<template>
  <div class="charts">
    <div style="position: fixed; top: 10rem; width: 100%; z-index: 999">
      <van-loading type="spinner" vertical color="#1989fa" v-if="loading" />
    </div>
    <div
      v-if="showBtn"
      class="time-btns"
      style="width: 200px; position: absolute; right: 0; z-index: 1"
    >
      <van-button
        :type="currentTime === 'minute' ? 'info' : 'default'"
        size="small"
        @click="nearTenMinute"
        >近十分钟
      </van-button>
      <van-button
        :type="currentTime === 'hour' ? 'info' : 'default'"
        size="small"
        @click="nearOneHour"
        >近一小时
      </van-button>
      <van-button
        :type="currentTime === 'day' ? 'info' : 'default'"
        size="small"
        @click="nearOneDay"
        >近一天
      </van-button>
    </div>
    <div id="line-chart"></div>
  </div>
</template>

<script>
import { Getchart, GetdeviceData, Getdevice } from '@/api/index'
import echarts from 'echarts'

export default {
  name: "echats",
  props: {
    showBtn: Boolean
  },
  data() {
    return {
      device_id: '',
      code: '',
      name: '',
      unit: '',
      chartData: [],
      mappingData: [],
      value: '',
      showPicker: false,
      timer: null,
      loading: false,
      currentTime: 'hour'
    }
  },
  created() {
    // if(this.$route.query.id){
    //     this.device_id = this.$route.query.id
    //     this.code = this.$route.query.code
    //     this.name = this.$route.query.name
    //     this.unit = this.$route.query.unit
    //     // this.getdevice()
    // }
  },
  mounted() {
  },
  beforeDestory() {
    clearInterval(this.timer)
  },
  methods: {
    nearTenMinute() {
      this.currentTime = 'minute'
      this.getPointHistory()
    },
    nearOneHour() {
      this.currentTime = 'hour'
      this.getPointHistory()
    },
    nearOneDay() {
      this.currentTime = 'day'
      this.getPointHistory()
    },
    realClick(val) {
      if (val) {
        this.lineChartVisible = true
        this.currentNode = val
        this.getPointHistory()
      }
    },
    // 获取设备分类列表标题
    getdevice(datas) {
      this.loading = true
      this.device_id = datas.id
      this.code = datas.code
      this.name = datas.name
      this.unit = datas.unit
      this.value_range = datas.value_range
      const data = {
        limit: 1000,
        offset: 0,
        q: '',
        device_id: this.device_id
      }
      Getdevice(data).then((response) => {
        const a = response.data.results.map(v => ({ ...v, code: v.ali_identifier, name: v.display_name || v.ali_name, unit: v.display_unit || v.ali_unit }))

        const datalist = a.map(item => {
          return { ...item, text: item.name }
        })
        this.mappingData = datalist

        this.getPointHistory()
      })
    },
    onConfirm(value) {
      this.code = value.code
      this.name = value.name
      this.unit = value.unit
      this.getPointHistory()
      this.showPicker = false
    },
    async getPointHistory() {
      let start
      if (this.currentTime === 'minute') {
        start = new Date(Date.now() - 10 * 60 * 1000)
      } else if (this.currentTime === 'hour') {
        start = new Date(Date.now() - 60 * 60 * 1000)
      } else if (this.currentTime === 'day') {
        start = new Date(Date.now() - 24 * 60 * 60 * 1000)
      }
      this.loading = true
      try {
        this.chartData = []
        this.chartVisible = true
        const postData = {
          device_id: this.device_id,
          metrics: [this.code],
          start,
          end: new Date()
        }
        const res = await Getchart(postData)
        this.initLineChart(res.data[0] ? res.data[0].dps : {}, this.name, this.unit, this.value_range)
      } catch (e) {
        console.log(e)

      } finally {
        this.chartVisible = false
        this.loading = false
      }
    },
    initLineChart(data = {}, name, unit = '', value_range = null) {
      const values = []
      for (const key in data) {
        values.push([parseFloat(key), parseFloat(data[key]).toFixed(2)])
      }
      if (!this.lineChart) {
        this.lineChart = echarts.init(document.getElementById('line-chart'))
      }
      console.log('value_range', value_range)
      this.chartData = values
      const option = {
        title: {
          text: name,
          textStyle: {
            fontSize: 12
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            },

          }
        },
        formatter(val) {
          let str = ''
          val.forEach((v, index) => {
            const { value = [], seriesName = '' } = v
            const floatValue = parseFloat(value[1] || 0).toFixed(2)

            str += `
                  <div>
                      ${v.marker}
                      <span>${seriesName}</span>
                      <span>${floatValue}</span>
                      <span>${unit}</span>
                  </div>
              `
          })
          return str
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'time',
            splitLine: {
              show: false
            }
          }
        ],
        yAxis: [
          {
            type: 'value',
            name: unit,
            min: function (val) {
              return (value_range === null || value_range === undefined) ? undefined : Math.min(val.min, parseFloat(value_range.split(',')[0]))
            },
            max: function (val) {
              return (value_range === null || value_range === undefined) ? undefined : Math.max(val.max, parseFloat(value_range.split(',')[1]))
            }
          }
        ],
        // yAxis: [
        //   {
        //     type: 'value',
        //     name: unit
        //   }
        // ],
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: 100
          }
        ],
        series: [
          {
            name,
            type: 'line',
            stack: '总量',
            lineStyle: {
              color: 'rgba(24, 144, 255, 1)'
            },
            showSymbol: false,
            smooth: true,
            data: values
          }
        ]
      }
      this.lineChart.setOption(option, true)
      this.timer = setInterval(() => { // 某些定时器操作
        this.getGetdeviceData()
      }, 10000); // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。   
    },
    getGetdeviceData() {
      GetdeviceData({ device_id: this.device_id, metrics: [] }).then((response) => {
        const res = response.data
        const temp = (res || []).find(v => v.metric === this.code)
        if (temp) {
          this.updateChart(temp)
        }
      })
    },
    updateChart(node) {
      const data = [...this.chartData, [node.timestamp, typeof node.value === 'number' ? node.value : 0]]
      if (this.lineChart) {
        console.log(this.lineChart)
        this.lineChart.setOption({
          series: [{
            data
          }]
        })
      }
    },
    drawDown() {
      let myChart = echarts.init(document.getElementById('down-chart'))
      myChart.setOption({
        title: {
          text: '堆叠区域图',
          show: false
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          data: ['邮件营销', '联盟广告', '视频广告', '直接访问', '搜索引擎'],
          show: false
        },
        toolbox: {

        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: ['周一', '周二', '周三', '周四', '周五', '周六', '周日']
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            name: '邮件营销',
            type: 'line',
            stack: '总量',
            data: [120, 132, 101, 134, 90, 230, 210]
          },
          {
            name: '联盟广告',
            type: 'line',
            stack: '总量',
            data: [220, 182, 191, 234, 290, 330, 310]
          },
          {
            name: '视频广告',
            type: 'line',
            stack: '总量',
            data: [150, 232, 201, 154, 190, 330, 410]
          },
          {
            name: '直接访问',
            type: 'line',
            stack: '总量',
            data: [320, 332, 301, 334, 390, 330, 320]
          },
          {
            name: '搜索引擎',
            type: 'line',
            stack: '总量',
            label: {
              normal: {
                show: true,
                position: 'top'
              }
            },
            data: [820, 932, 901, 934, 1290, 1330, 1320]
          }
        ]
      })
    },
  }
}
</script>

<style lang="less" scoped>
.charts {
  padding-top: 10px;
  #line-chart {
    height: 300px;
    width: 100%;
  }
  .filters {
    margin-left: 3vw;
    margin-top: 3vw;
    width: 40%;
  }
}
</style>
