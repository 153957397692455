<template>
  <div class="monitor" style="padding-bottom: 55px">
    <div style="position: fixed; top: 10rem; width: 100%; z-index: 999">
      <van-loading type="spinner" vertical color="#1989fa" v-if="loading" />
    </div>
    <div class="headers">
      <van-field
        readonly
        clickable
        name="picker"
        :value="value"
        placeholder="下拉选择"
        @click="showPicker = true"
      />
      <van-popup v-model="showPicker" position="bottom">
        <van-picker
          ref="pick"
          show-toolbar
          :columns="columns"
          @confirm="onConfirm"
          @cancel="showPicker = false"
        >
        </van-picker>
      </van-popup>
      <div class="detail" @click="getDeviceDetail">设备详情</div>
    </div>
    <div v-if="!firstLoad" class="sub-headers">
      <div class="status">
        设备状态:
        <span style="padding-left: 0.2rem">
          <img
            width="14px"
            style="top: 2px; position: relative"
            :src="getStatusImage(detailList)"
          />
          {{ detailList.status }}
        </span>
      </div>
      <div class="time">
        数据更新时间:
        <span
          :style="{
            paddingLeft: '0.4rem',
            color: getTimeColor(
              device_value.length > 0 ? device_value[0].timestamp : undefined,
              detailList.status === '离线',
              detailList.status === '未激活'
            ),
          }"
          >{{
            device_value.length > 0
              ? moment(device_value[0].timestamp).format("YYYY-MM-DD HH:mm:ss")
              : "-"
          }}</span
        >
      </div>
    </div>
    <van-tabs type="card" class="cardlist" v-show="toggle">
      <van-tab title="监测值">
        <span style="font-size: 13px; margin: 10px; display: block"
          >展示次要测点:<van-switch
            v-model="isprohibit"
            size="13px"
            style="margin-top: 3px; margin-top: -4px; vertical-align: middle"
        /></span>
        <van-search
          v-model="searchValue"
          placeholder="请输入名称"
          @input="searchList"
        />
        <van-tabs>
          <van-tab
            class="van-tabp"
            :style="{ 'max-height': 0.5 * height + 'px' }"
            :title="item.name"
            v-for="(item, index) in realCards"
            :key="index"
          >
            <template v-for="(it, itindex) in item.children">
              <template v-if="it.disable_display && !isprohibit"> </template>
              <van-cell is-link @click="toChart(it)" :key="itindex" v-else>
                <template
                  #title
                  style="display: flex; justify-content: space-between"
                >
                  <!--                <span class="codes" @click.stop="showCode(it.code)">{{ it.code }}</span>-->
                  <span class="custom-title">{{ it.name }}</span>
                  <span class="content">{{ it.value }} {{ it.unit }}</span>
                </template>
              </van-cell>
            </template>
          </van-tab>
        </van-tabs>
      </van-tab>
      <van-tab title="状态值">
        <span style="font-size: 13px; margin: 10px; display: block"
          >展示次要测点:<van-switch
            v-model="isprohibit"
            size="13px"
            style="margin-top: 3px; margin-top: -4px; vertical-align: middle"
        /></span>
        <van-search
          v-model="searchValue2"
          placeholder="请输入名称"
          @input="searchList2"
        />
        <div style="overflow: hidden; margin-bottom: 10px">
          <span style="float: right"
            ><b
              class="statecolor"
              style="float: left; margin-top: 5px; margin-right: 3px"
            ></b
            >开</span
          >
          <span style="float: right; margin-right: 5px"
            ><b
              class="statecolor2"
              style="float: left; margin-top: 5px; margin-right: 3px"
            ></b
            >关</span
          >
        </div>
        <van-tabs>
          <van-tab
            class="van-tabp"
            :style="{ 'max-height': 0.5 * height - 30 + 'px' }"
            :title="item.name"
            v-for="(item, index) in boolCards"
            :key="index"
          >
            <template v-for="(it, itindex) in item.children">
              <template v-if="it.disable_display && !isprohibit"> </template>
              <van-cell :key="itindex" v-else>
                <template
                  #title
                  style="display: flex; justify-content: space-between"
                >
                  <!--                <span class="codes" @click.stop="showCode(it.code)">{{ it.code }}</span>-->
                  <span class="custom-title">{{ it.name }}</span>
                  <span class="content"
                    ><b
                      class="statecolor"
                      v-if="it.value === 1"
                      :class="
                        detailList.status !== '在线' ? 'statecolor-offline' : ''
                      "
                    ></b
                    ><b
                      class="statecolor2"
                      :class="
                        detailList.status !== '在线' ? 'statecolor-offline' : ''
                      "
                      v-else
                    ></b
                  ></span>
                </template>
              </van-cell>
            </template>
          </van-tab>
        </van-tabs>
      </van-tab>
      <van-tab title="故障值">
        <span style="font-size: 13px; margin: 10px; display: block"
          >展示次要测点:<van-switch
            v-model="isprohibit"
            size="13px"
            style="margin-top: 3px; margin-top: -4px; vertical-align: middle"
        /></span>
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <van-search
            v-model="searchValue3"
            placeholder="请输入故障名称"
            style="width: 10%"
            @input="searchList3"
          />
          <van-badge
            :content="isall ? accidents.length : null"
            style="margin-right: 1%"
          >
            <van-button
              v-if="isall"
              type="default"
              size="small"
              style="width: 70px; margin-left: 10px"
              @click="isall = false"
              >留存故障
            </van-button>
            <van-button
              v-else
              type="default"
              size="small"
              style="width: 70px; margin-left: 10px"
              @click="isall = true"
            >
              查看所有
            </van-button>
          </van-badge>
        </div>
        <div style="overflow: hidden; margin-bottom: 10px">
          <span v-if="isall" style="float: right"
            ><b
              class="statecolor3"
              style="float: left; margin-top: 5px; margin-right: 3px"
            ></b
            >故障</span
          >
          <span v-if="isall" style="float: right; margin-right: 5px"
            ><b
              class="statecolor"
              style="float: left; margin-top: 5px; margin-right: 3px"
            ></b
            >正常</span
          >
          <!-- <span style="float:right;font-size:13px;margin-right:10px;">展示禁止:<van-switch v-model="isprohibit" size="13px"
                                                                                       style="margin-top:3px;margin-top: -4px;
    vertical-align: middle;"/></span> -->
        </div>
        <van-tabs v-if="isall">
          <van-tab
            class="van-tabp"
            :style="{ 'max-height': 0.5 * height - 30 + 'px' }"
            :title="item.name"
            v-for="(item, index) in isprohibit ? eventCards : filterEventCards"
            :key="index"
          >
            <van-cell v-for="(it, itindex) in item.children" :key="itindex">
              <template
                #title
                style="display: flex; justify-content: space-between"
              >
                <!--                <span class="codes" @click.stop="showCode(it.code)">{{ it.code }}</span>-->
                <span class="custom-title">{{ it.name }}</span>
                <span class="content"
                  ><b
                    class="statecolor3"
                    v-if="accdentNames.indexOf(it.name) >= 0"
                    :class="
                      detailList.status !== '在线' ? 'statecolor-offline' : ''
                    "
                  ></b
                  ><b
                    class="statecolor"
                    v-else
                    :class="
                      detailList.status !== '在线' ? 'statecolor-offline' : ''
                    "
                  ></b
                ></span>
              </template>
            </van-cell>
          </van-tab>
        </van-tabs>
        <div class="details" v-else>
          <div class="roblist" v-for="(item, index) in accidents" :key="index">
            <p class="title">{{ item.name }}</p>
            <p class="monitor" style="background: #fff">
              故障时间<span>{{ item.time }}</span>
            </p>
            <!-- <p class="monitor" style="background:#fff">PCL标识<span>{{ item.pcl }}</span></p> -->
          </div>
        </div>
        <!-- <van-empty v-else description="暂无数据" /> -->
      </van-tab>
    </van-tabs>

    <van-dialog v-model="show" title="设备详情" show-cancel-button>
      <div class="device">
        <van-cell-group>
          <van-cell
            value-class="value-width"
            title="设备名称"
            :value="detailList.name"
          />
        </van-cell-group>
        <!-- <van-cell-group>
          <van-cell title="产品名称" :value="detailList.ali_product_name" />
        </van-cell-group> -->
        <van-cell-group>
          <van-cell
            title="设备所属组织"
            :value="detailList.organization_name"
          />
        </van-cell-group>

        <van-cell-group>
          <van-cell
            :title="
              '设备维修人员' +
              (detailList.provider_operators.length > 0
                ? '(共' + detailList.provider_operators.length + '人)'
                : '(暂无)')
            "
            style="font-weight: bold"
          />
        </van-cell-group>
        <div class="scroll-section">
          <van-cell-group
            v-for="(item, index) in detailList.provider_operators"
            :key="index"
          >
            <van-cell>
              <template #title>
                <p style="margin: 0">
                  <span style="display: inline-block; width: 50px">{{
                    item.user_name
                  }}</span>
                  <span>({{ item.user_position }})</span>
                </p>
              </template>
              <template #label>
                <p style="margin: 0">
                  <span>{{ item.user_phone }}</span>
                </p>
              </template>
              <template #default>
                告警推送:
                <van-switch v-model="item.is_send_alarm" size="12px" disabled />
              </template>
            </van-cell>
          </van-cell-group>
        </div>
        <van-cell-group>
          <van-cell
            :title="
              '设备管理人员' +
              (detailList.consumer_operators.length > 0
                ? '(共' + detailList.consumer_operators.length + '人)'
                : '(暂无)')
            "
            style="font-weight: bold"
          />
        </van-cell-group>
        <div class="scroll-section">
          <van-cell-group
            v-for="(item, index) in detailList.consumer_operators"
            :key="index"
          >
            <van-cell>
              <template #title>
                <p style="margin: 0">
                  <span style="display: inline-block; width: 50px">{{
                    item.user_name
                  }}</span>
                  <span>({{ item.user_position }})</span>
                </p>
              </template>
              <template #label>
                <p style="margin: 0">
                  <span>{{ item.user_phone }}</span>
                </p>
              </template>
              <template #default>
                告警推送:
                <van-switch v-model="item.is_send_alarm" disabled size="12px" />
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
    </van-dialog>

    <van-dialog v-model="showfault" title="故障信息" show-cancel-button>
      <div class="device">
        <!-- <van-cell-group>
          <van-cell title="新故障"/>
        </van-cell-group> -->
        <van-cell-group>
          <van-cell title="故障名称">
            <template #default> 故障时间 </template>
          </van-cell>
        </van-cell-group>
        <div v-for="(item, index) in accidents" :key="index">
          <van-cell-group v-if="item.isNew">
            <van-cell :title="item.name">
              <template #default>
                {{ item.time }}
              </template>
            </van-cell>
          </van-cell-group>
        </div>
      </div>
    </van-dialog>

    <!-- 数据图 -->
    <van-dialog v-model="showchart" title="">
      <div class="showchart">
        <Chart ref="chart" show-btn />
      </div>
    </van-dialog>
  </div>
</template>

<script>
import {
  Overview,
  Getdevice,
  GetdeviceData,
  Udevice,
  deviceConfig,
  latest,
  event,
} from "@/api/index";
import { Notify } from "vant";
import dayjs from "dayjs";
import Chart from "@/views/chart/index";
import moment from "moment";

export default {
  name: "monitor",
  components: {
    Chart,
  },
  data() {
    return {
      realCardsModel: false,
      boolCardsModel: false,
      eventCardsModel: false,
      toggle: true,
      loading: false,
      firstLoad: true,
      height: null,
      isprohibit: false,
      isall: true,
      show: false,
      showchart: false,
      value: "",
      columns: [],
      showPicker: false,
      searchValue: "",
      searchValue2: "",
      searchValue3: "",
      list: [],
      datalist: [],
      device_id: "",
      classify_config: {
        bool: [
          {
            children: [],
          },
        ],
      },
      realData: [], // 监测实时数据
      boolData: [], // 状态数据
      eventData: [], // 故障数据
      mappingData: [],
      deviceDetail: [],
      realCards: [],
      boolCards: [],
      eventCards: [],
      filterEventCards: [],
      detailList: {},
      accidents: [],
      showfault: false,
      device_value: [],
      realCardsdata: [],
      boolCardsdata: [],
      eventCardsdata: [],
      accdentNames: [],
      timer: null,
    };
  },
  created() {
    if (this.$route.query.id) {
      this.device_id = this.$route.query.id;
      this.value = this.$route.query.device_name;
      this.getConfig();
      this.getList(true);
    } else {
      this.getList(false);
    }
  },
  mounted() {
    this.height = window.screen.height;
    this.$once("hook:beforeDestroy", () => {
      clearInterval(this.timer);
    });
  },
  beforeDestory() {
    clearInterval(this.timer);
  },
  watch: {
    isprohibit() {
      this.getLatestEvent();
    },
  },
  methods: {
    getStatusImage(device) {
      const metric = this.device_value && this.device_value.length > 0 ? this.device_value[0] : { timestamp: undefined }
      const path = this.getTimePath(metric.timestamp, device.status === '离线', device.status === '未激活')
      return path
    },
    getTimePath(time, offLine = false, deactive = false) {
      if (!time || offLine) return require('@/assets/bigscreen/offline.png')
      if (deactive) return require('@/assets/bigscreen/deactive.png')
      if (this.showError) return require('@/assets/bigscreen/interrup.png')

      const date = new Date(time)
      const now = new Date()
      if (now - date < 1000 * 60) {
        return require('@/assets/bigscreen/online.png')
      } else if (now - date < 1000 * 60 * 60) {
        return require('@/assets/bigscreen/interrup.png')
      } else {
        return require('@/assets/bigscreen/numb.png')
      }
    },
    getTimeColor(time, offLine = false, deactive = false) {
      if (offLine) return '#909399'
      if (deactive) return '#000'
      if (!time || this.showError) return '#e42b2b'

      const date = new Date(time)
      const now = new Date()
      if (now - date < 1000 * 60) {
        return '#409eff'
      } else if (now - date < 1000 * 60 * 60) {
        return '#f3ab3f'
      } else {
        return '#e42b2b'
      }
    },
    moment,
    toChart(item) {
      this.showchart = true;
      this.$nextTick(() => {
        const data = {
          id: this.device_id,
          name: item.name,
          code: item.code,
          unit: item.unit,
          value_range: item.value_range
        };
        this.$refs.chart.getdevice(data);
      });
    },
    showfaultbtn() {
      // this.$router.push({path:'/content/fault',query:{list:JSON.stringify(this.accidents)}})
    },
    onConfirm(value) {
      this.value = value.text;
      this.showPicker = false;
      this.device_id = value.id;
      this.getConfig();
    },
    getConfig() {
      this.toggle = false;
      this.loading = true;
      deviceConfig(this.device_id).then((response) => {
        console.log("MAPPING", response);
        this.loading = false;
        this.realCardsdata = response.data.real;
        this.boolCardsdata = response.data.bool;
        this.eventCardsdata = response.data.event;
        this.getdevice();
        this.firstLoad = false
      });
      Udevice(this.device_id).then((response) => {
        const res = response.data
        // res.consumer_operators = res.consumer_operators.filter(v => v.person_account)
        res.provider_operators = res.provider_operators.filter(v => v.person_account)
        this.detailList = res;
      });
    },
    getList(status) {
      this.loading = true;
      const data = {
        limit: 1000,
        offset: 0,
      };
      Overview(data).then((response) => {
        this.loading = false;
        const datalist = response.data.results.map((item) => {
          return { ...item, text: item.device_name };
        });
        this.columns = datalist;
        // this.value = this.device_id
        if (!status) {
          this.value = this.columns[0].text;
          this.device_id = this.columns[0].id;
          this.getConfig();
        }
        this.initDeviceName(this.device_id)
      });
    },
    initDeviceName(id) {
      this.columns.some(v => {
        if (v.id.toString() === id.toString()) {
          this.value = v.device_name
          return true
        }
      })
    },
    getevent() {
      const data = {
        time_from: "2020-12-30T14:40:28.797Z",
        time_to: "2021-01-29T14:40:28.797Z",
        point_name: "",
        device_ids: this.device_id,
        limit: 20,
        offset: 0,
        show_disabled: this.isprohibit,
      };
      event(data).then((response) => {
        console.log(response);
        // const datalist = response.data.results.map(item => {
        //   return {...item,  text: item.device_name}
        // })
        // this.columns = datalist
      });
    },
    // 获取设备分类的值
    getGetdeviceData() {
      this.loading = true;
      GetdeviceData({ device_id: this.device_id, metrics: [] }).then(
        (response) => {
          this.toggle = true;
          this.loading = false;
          const res = response.data;
          this.device_value = res;
          this.realData = this.mappingData
            .filter((v) => v.type === "REAL")
            .map((v) => {
              const temp = (res || []).find(
                (v2) => v2.metric === v.ali_identifier
              );
              const value = temp ? parseFloat(temp.value).toFixed(2) : "--";
              return { ...v, value };
            });
          this.boolData = this.mappingData
            .filter((v) => v.type === "BOOL")
            .map((v) => {
              const temp = (res || []).find(
                (v2) => v2.metric === v.ali_identifier
              );
              const value = temp ? temp.value : "";
              return { ...v, value };
            });
          // this.eventData = this.mappingData
          //     .filter(v => v.type === 'EVENT')
          //     .map(v => {
          //       const temp = (res || []).find(v2 => v2.metric === v.ali_identifier)
          //       const value = temp ? temp.value : ''
          //       return {...v, value}
          //     })
          const list = [];
          var a = this.realCardsdata;
          var b = this.realData;
          if (a && a.length) {
            a.forEach((item) => {
              const current = {
                name: item.name,
                children: [],
              };
              if (item.children && item.children.length) {
                item.children.forEach((it) => {
                  const obj = b.find((ele) => ele.ali_identifier === it);
                  if (obj) {
                    current.children.push(obj);
                  }
                });
              }
              list.push(current);
            });
            // console.log('------',this)
            this.realCards = list;
          }

          // 状态数据
          const boolList = [];
          var aa = this.boolCardsdata;
          var bb = this.boolData;
          if (aa && aa.length) {
            aa.forEach((item) => {
              const current = {
                name: item.name,
                children: [],
              };
              if (item.children && item.children.length) {
                item.children.forEach((it) => {
                  const obj = bb.find((ele) => ele.ali_identifier === it);
                  if (obj) {
                    current.children.push(obj);
                  }
                });
              }
              boolList.push(current);
            });
            this.boolCards = boolList;
          }
          this.getevent();
          // 故障数据
          const eventList = [];
          var aaa = this.eventCardsdata;
          var bbb = this.eventData;
          if (aaa && aaa.length) {
            aaa.forEach((item) => {
              const current = {
                name: item.name,
                children: [],
              };
              if (item.children && item.children.length) {
                item.children.forEach((it) => {
                  const obj = bbb.find((ele) => ele.ali_identifier === it);
                  if (obj) {
                    current.children.push(obj);
                  }
                });
              }
              eventList.push(current);
            });
            this.eventCards = eventList;
            this.filterEventCards = eventList.map((item) => {
              return {
                ...item,
                children: item.children.filter((ele) => !ele.disable_display),
              };
            });
          } else {
            this.eventCards = [];
            this.filterEventCards = [];
          }
          this.getLatestEvent();
        }
      );
    },
    // 获取设备分类列表标题
    getdevice() {
      this.loading = true;

      const data = {
        limit: 1000,
        offset: 0,
        q: "",
        device_id: this.device_id,
      };
      Getdevice(data).then((response) => {
        this.loading = false;
        this.mappingData = response.data.results.map((v) => ({
          ...v,
          code: v.ali_identifier,
          name: v.display_name || v.ali_name,
          unit: v.display_unit || v.ali_unit,
        }));
        this.getGetdeviceData();
        this.timer = setInterval(() => {
          this.getGetdeviceData();
        }, 10000);
      });
    },
    showCode(code) {
      Notify({ type: "primary", message: code });
    },
    detail() { },
    getDeviceDetail() {
      Udevice(this.device_id).then((response) => {
        this.show = true;
        const res = response.data
        // res.consumer_operators = res.consumer_operators.filter(v => v.person_account)
        res.provider_operators = res.provider_operators.filter(v => v.person_account)
        this.detailList = res;
      });
    },
    getAllType(type = "REAL") {
      let children = [];
      if (type === "REAL") {
        children = this.realData.map((v) => v.ali_identifier);
      } else if (type === "BOOL") {
        children = this.boolData.map((v) => v.ali_identifier);
      } else if (type === "EVENT") {
        children = this.eventData.map((v) => v.ali_identifier);
      }
      return [
        {
          name: "默认分类",
          children,
        },
      ];
    },
    async getLatestEvent() {
      try {
        const res = await latest({
          device_id: this.device_id,
          show_disabled: this.isprohibit,
        });
        this.eventData = this.mappingData
          .filter((v) => v.type === "EVENT")
          .map((v) => {
            const temp =
              (res.data || []).find(
                (v2) => v2.ali_identifier === v.ali_identifier
              ) || {};
            return { ...v, eventName: temp.name || "" };
          });
        this.getAccidents(res.data);
      } catch (e) {
        console.log(e);
      }
    },
    getAccidents(res = []) {
      try {
        this.accidents = res.map((v) => {
          const temp = this.accidents.find((v2) => {
            const p1 =
              v.name + dayjs(v.time).format("YYYY-MM-DD HH:mm:ss").toString();
            const p2 =
              v2.name + dayjs(v2.time).format("YYYY-MM-DD HH:mm:ss").toString();
            return p1 === p2;
          });
          console.log("eventCards", this.eventCards);

          // this.eventCards = this.eventCards.forEach(item => {
          //   item.children.forEach(child => {
          //     child.isNew = names.indexOf(child.ali_name) > 0
          //   })
          // })
          console.log("eventCards", this.eventCards);

          // 之前存在并且没有被确认过，就不是新故障
          if (temp && temp.isNew === false) {
            return {
              name: v.name,
              time: dayjs(v.time).format("YYYY-MM-DD HH:mm:ss"),
              isNew: false,
            };
          } else {
            return {
              name: v.name,
              time: dayjs(v.time).format("YYYY-MM-DD HH:mm:ss"),
              isNew: true,
            };
          }
        });
        console.log("accidents", this.accidents);
        this.accdentNames = this.accidents.map((item) => item.name);
        console.log("accdentNames", this.accdentNames);
      } catch (e) {
        console.log(e);
      }
    },
    // 搜索
    searchList() {
      const data = {
        limit: 1000,
        offset: 0,
        q: this.searchValue,
        device_id: this.device_id,
      };
      Getdevice(data).then((response) => {
        this.mappingData = response.data.results.map((v) => ({
          ...v,
          code: v.ali_identifier,
          name: v.display_name || v.ali_name,
          unit: v.display_unit || v.ali_unit,
        }));
        const realData = this.mappingData
          .filter((v) => v.type === "REAL")
          .map((v) => {
            const temp = (this.device_value || []).find(
              (v2) => v2.metric === v.ali_identifier
            );
            const value = temp ? parseFloat(temp.value).toFixed(2) : "--";
            return { ...v, value };
          });
        const list = [];
        var a = this.realCardsdata;
        var b = realData;
        if (a && a.length) {
          a.forEach((item) => {
            const current = {
              name: item.name,
              children: [],
            };
            if (item.children && item.children.length) {
              item.children.forEach((it) => {
                const obj = b.find((ele) => ele.ali_identifier === it);
                if (obj) {
                  current.children.push(obj);
                }
              });
            }
            list.push(current);
          });
          this.realCards = list;
        }
      });
    },
    // 搜索
    searchList2() {
      const data = {
        limit: 1000,
        offset: 0,
        q: this.searchValue2,
        device_id: this.device_id,
      };
      Getdevice(data).then((response) => {
        this.mappingData = response.data.results.map((v) => ({
          ...v,
          code: v.ali_identifier,
          name: v.display_name || v.ali_name,
          unit: v.display_unit || v.ali_unit,
        }));
        const boolData = this.mappingData
          .filter((v) => v.type === "BOOL")
          .map((v) => {
            const temp = (this.device_value || []).find(
              (v2) => v2.metric === v.ali_identifier
            );
            const value = temp ? parseFloat(temp.value).toFixed(2) : "--";
            return { ...v, value };
          });
        const list = [];
        var a = this.boolCardsdata;
        var b = boolData;
        if (a && a.length) {
          a.forEach((item) => {
            const current = {
              name: item.name,
              children: [],
            };
            if (item.children && item.children.length) {
              item.children.forEach((it) => {
                const obj = b.find((ele) => ele.ali_identifier === it);
                if (obj) {
                  current.children.push(obj);
                }
              });
            }
            list.push(current);
          });
          this.boolCards = list;
        }
      });
    },
    // 搜索
    searchList3() {
      const data = {
        limit: 1000,
        offset: 0,
        q: this.searchValue3,
        device_id: this.device_id,
      };
      Getdevice(data).then((response) => {
        this.mappingData = response.data.results.map((v) => ({
          ...v,
          code: v.ali_identifier,
          name: v.display_name || v.ali_name,
          unit: v.display_unit || v.ali_unit,
        }));
        const eventData = this.mappingData
          .filter((v) => v.type === "EVENT")
          .map((v) => {
            const temp = (this.device_value || []).find(
              (v2) => v2.metric === v.ali_identifier
            );
            const value = temp ? parseFloat(temp.value).toFixed(2) : "--";
            return { ...v, value };
          });
        const list = [];
        var a = this.eventCardsdata;
        var b = eventData;
        if (a && a.length) {
          a.forEach((item) => {
            const current = {
              name: item.name,
              children: [],
            };
            if (item.children && item.children.length) {
              item.children.forEach((it) => {
                const obj = b.find((ele) => ele.ali_identifier === it);
                if (obj) {
                  current.children.push(obj);
                }
              });
            }
            list.push(current);
          });
          this.enentCards = list;
        }
        this.getGetdeviceData();
      });
    },
  },
};
</script>

<style lang="less">
.monitor {
  background: #f3f3f3;
  padding-bottom: 10px;
  .statecolor {
    width: 12px;
    height: 12px;
    background: rgb(81, 204, 63);
    display: block;
    border-radius: 50%;
    margin-top: 5px;
  }

  .statecolor2 {
    width: 12px;
    height: 12px;
    background: #c0c4cc;
    display: block;
    border-radius: 50%;
    margin-top: 5px;
  }

  .statecolor3 {
    width: 12px;
    height: 12px;
    background: rgb(255, 92, 92);
    display: block;
    border-radius: 50%;
    margin-top: 5px;
  }
  .statecolor-offline {
    background: #c0c4cc;
  }
  .van-tabs__line {
    background-color: #0075ff !important;
  }

  .van-field__control {
    background: #fff !important;
    padding: 5px 11px !important;
    width: 100% !important;
  }

  .van-field__left-icon {
    margin-top: 6px !important;
  }

  .van-search {
    background: #f3f3f3 !important;
    padding: 0.5rem 0 !important;
    width: 100% !important;

    .van-search__content {
      width: 100% !important;
    }
  }

  .headers {
    display: flex;

    .detail {
      width: 90px;
      padding-right: 1rem;
      text-align: left;
      color: #0075ff;
      line-height: 52px;
    }

    .van-cell {
      background-color: #f3f3f3;
    }
  }

  .sub-headers {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.4rem 1rem;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 0.4rem;

    .status {
      flex: 1;
    }

    .time {
      flex: 2;
    }
  }

  .cardlist {
    padding: 0 1rem;

    .van-cell__title {
      display: flex !important;
      justify-content: space-between !important;
    }

    .codes {
      width: 25%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      display: inline-block;
      margin-right: 10%;
    }

    .custom-title {
      text-align: left;
      display: inline-block;
      width: 50%;
      overflow: hidden;
    }

    .content {
      display: inline-block;
      width: 25%;
      text-align: right;
      position: relative;

      b {
        position: absolute;
        right: 0;
      }
    }

    .types {
      margin-top: 10px;

      li {
        width: 18%;
        float: left;
        text-align: center;
        margin-bottom: 10px;
        padding: 1%;
      }

      .active {
        background: #fff;
      }
    }

    .van-tabp {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    .van-tabs__nav--card {
      margin: 0 !important;
      border: 1px solid #0075ff !important;
    }

    .van-tabs__nav--card .van-tab.van-tab--active {
      color: #fff !important;
      background: #0075ff !important;
    }

    .van-tabs__nav--card .van-tab {
      color: #0075ff !important;
      border-right: 1px solid #0075ff !important;
    }

    .van-tabs__nav--card .van-tab:last-child {
      border-right: none !important;
    }
  }

  .details {
    background: #f3f3f3;
    margin-bottom: 55px;
    padding: 0 1%;
    padding-top: 5%;

    .van-search {
      background: #f3f3f3;
    }

    .van-search__content {
      background: #fff;
    }

    .roblist {
      padding: 0 1rem;
      border-bottom: 1px solid #ccc;
      background: #fff;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border-radius: 4px;
      padding-top: 0.4rem;
      margin-bottom: 0.4rem;

      p {
        margin: 0.35rem 0;
        font-size: 13px;
      }

      .monitor {
        span {
          margin-left: 10px;
          float: right;
        }
      }

      .title {
        font-size: 13px;
        font-weight: 800;
        border-bottom: 1px solid #ccc;
        padding-bottom: 15px;

        span {
          float: right;
          display: flex;
          align-items: center;

          b {
            display: block;
            width: 12px;
            height: 12px;
            background: red;
            border-radius: 50%;
            margin-right: 2px;
          }

          .now {
            background: linear-gradient(180deg, #33caba, #2baa5d);
          }
        }
      }

      .subtitle {
        font-size: 13px;
        color: #999;
        margin-bottom: 10px;
      }

      .warning {
        font-size: 12px;
        color: red;
      }
    }

    .roblist:nth-child(2) {
      border-top: 1px solid #ccc;
    }
  }
}
.scroll-section {
  max-height: 130px;
  overflow-x: hidden;
}
.value-width {
  min-width: 200px;
}
</style>
